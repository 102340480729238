import { type MetaFunction } from '@remix-run/node'
import { Button } from '#app/components/ui/button.js'
import { Link, useRouteLoaderData } from '@remix-run/react'
import { type loader as rootLoader } from '#app/root.tsx'

export const meta: MetaFunction = () => [{ title: '法技小幫手' }]

export default function Index() {
	const rootLoaderData = useRouteLoaderData<typeof rootLoader>('root')
	const isEitherOnePracticing =
		rootLoaderData?.magicData?.practiceData ||
		rootLoaderData?.chiData?.practiceData

	return (
		<main className="font-poppins grid h-full place-items-center">
			<div className="grid place-items-center px-4 py-16 xl:grid-cols-2 xl:gap-24">
				<div className="flex max-w-md flex-col items-center text-center xl:order-2 xl:items-start xl:text-left">
					<p className="animate-slide-top [animation-fill-mode:backwards] xl:animate-slide-left xl:[animation-delay:0.5s] xl:[animation-fill-mode:backwards]">
						<img
							className="size-20 text-foreground xl:-mt-4"
							src="/img/redheart-320.png"
							alt="天帝教精神標誌 - 紅心"
						/>
					</p>
					<h1
						data-heading
						className="mt-8 animate-slide-top text-4xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:animate-slide-left xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						法技小幫手
					</h1>
					<p
						data-paragraph
						className="mt-6 animate-slide-top text-xl/7 text-muted-foreground [animation-delay:0.8s] [animation-fill-mode:backwards] xl:mt-8 xl:animate-slide-left xl:text-xl/6 xl:leading-10 xl:[animation-delay:1s] xl:[animation-fill-mode:backwards]"
					>
						一個簡單的小幫手，計算練法技的天數。
					</p>
					<div className="mt-6 flex items-center gap-2">
						<Button asChild variant="default" size="lg" className="text-lg">
							<Link to="/dashboard" prefetch="viewport">
								{isEitherOnePracticing ? '查看進度' : '立即設定'}
							</Link>
						</Button>
						<Button asChild variant="ghost" size="lg" className="text-lg">
							<Link to="/support" prefetch="intent">
								說明與支援
							</Link>
						</Button>
					</div>
				</div>
				<div className="mt-16 flex max-w-3xl flex-col items-center justify-center">
					<p>
						<img
							src="/img/lotus-400x600.jpg"
							alt="Lotus"
							className="h-56 w-screen overflow-hidden rounded-3xl object-cover md:h-auto md:w-96"
						/>
					</p>
					<p className="text-sm font-thin opacity-20">
						Photo by
						<a href="https://unsplash.com/@saffu?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
							{' '}
							Saffu{' '}
						</a>
						on
						<a href="https://unsplash.com/photos/shallow-focus-photography-of-purple-flower-pYaKs30p9zg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
							{' '}
							Unsplash
						</a>
					</p>
				</div>
			</div>
		</main>
	)
}
